<template>
  <TransitionRoot
    as="template"
    :show="generalStore.isSignInDrawerOpen || generalStore.isSignUpDrawerOpen"
  >
    <Dialog as="div" class="relative z-[999]" @close="closeDrawer(true)">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black opacity-50 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="pointer-events-auto relative w-screen max-w-[589px]"
              >
                <div
                  class="flex h-full flex-col justify-between overflow-auto bg-white pb-11.5 pt-20 shadow-xl"
                >
                  <div>
                    <div class="mb-9 flex items-center justify-between px-9.5">
                      <DialogTitle class="text-3xl font-medium text-gray-about">
                        {{ titleAndDescription.title }}
                      </DialogTitle>
                      <UiButton
                        variant="clear"
                        variant-type="icon"
                        size="xs"
                        :aria-label="
                          $t('ariaLabelClose', {
                            title: titleAndDescription.title,
                          })
                        "
                        @click="closeDrawer(true)"
                      >
                        <UiIcon name="close" class="h-6 w-6" />
                      </UiButton>
                    </div>
                    <p
                      v-if="titleAndDescription.description"
                      class="mb-9 px-9.5 font-normal leading-tight"
                    >
                      {{ titleAndDescription.description }}
                    </p>
                    <component
                      :is="components[formState]"
                      class="mb-10"
                      :disabled="!generalStore.isSignInDrawerOpen"
                      @changeForm="onChangeForm"
                    />
                  </div>
                  <div
                    v-if="generalStore.systemData.loginFooter"
                    class="text-center font-normal text-gray-about"
                    v-html="generalStore.systemData.loginFooter.content"
                  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { useGeneralStore } from "@/stores/general";
import { AuthDrawerEnum, AuthDrawerType } from "~/types/components.types";

const generalStore = useGeneralStore();

const route = useRoute();
const router = useRouter();

const components = {
  [AuthDrawerEnum.LOGIN]: resolveComponent("LazyAuthSignInForm"),
  [AuthDrawerEnum.REGISTER]: resolveComponent("LazyAuthSignUpForm"),
  [AuthDrawerEnum.FORGOT]: resolveComponent("LazyAuthForgotEmailForm"),
  [AuthDrawerEnum.RECOVERY]: resolveComponent("LazyAuthForgotPasswordForm"),
};

const formState = ref<AuthDrawerType>(
  (route.query.drawer as AuthDrawerType) || AuthDrawerEnum.LOGIN,
);

watchEffect(() => {
  if (generalStore.isSignInDrawerOpen) {
    formState.value =
      (route.query.drawer as AuthDrawerType) || AuthDrawerEnum.LOGIN;
  }
  if (generalStore.isSignUpDrawerOpen) {
    formState.value =
      (route.query.drawer as AuthDrawerType) || AuthDrawerEnum.REGISTER;
  }
});

const titleAndDescription = computed(() => {
  switch (formState.value) {
    case AuthDrawerEnum.REGISTER:
      return {
        title: "Sign up",
        description: "",
      };
    case AuthDrawerEnum.FORGOT:
      return {
        title: "Forgot my password",
        description:
          "Enter your registered email below and we’ll send instructions for password reset If your email is associated with an account.",
      };
    case AuthDrawerEnum.RECOVERY:
      return {
        title: "Create new password",
        description: "",
      };
    case AuthDrawerEnum.LOGIN:
    default:
      return {
        title: "Log In",
        description: "",
      };
  }
});

function onChangeForm(form: AuthDrawerType) {
  formState.value = form;
}

function closeDrawer(isReplace?: boolean) {
  generalStore.isSignInDrawerOpen = false;
  generalStore.isSignUpDrawerOpen = false;
  if (isReplace) {
    const query = { ...route.query };
    delete query.drawer;
    router.replace({ query });
  }
}
</script>
